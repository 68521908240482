'use strict';

// console.log('%crunning quickView.js in app_belkin!', 'color: green');
var base = require('./base');
var focusHelper = require('base/components/focus');
var formLabels = require('../components/formLabels');
/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="quickViewModal" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <a class="full-pdp-link" href=""></a>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalCompareHtmlElement() {
    if ($('#quickViewModalCompare').length !== 0) {
        $('#quickViewModalCompare').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="quickViewModalCompare" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <a class="full-pdp-link" href=""></a>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getQuickAddHtmlElement(parentButton) {
    if ($('#quickAddModal').length !== 0) {
        $('#quickAddModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="quickAddModal" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    var $parentTile = $(parentButton).parents('.product');
    $parentTile.append(htmlString);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getQuickAddCompareHtmlElement(parentButton) {
    if ($('#quickAddModalCompare').length !== 0) {
        $('#quickAddModalCompare').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="quickAddModalCompare" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    var $parentTile = $(parentButton).parents('.product');
    $parentTile.append(htmlString);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl) {
    $.spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('.modal-body').empty();
            $('.modal-body').html(parsedHtml.body);
            $('.modal-footer').html(parsedHtml.footer);
            $('.full-pdp-link').text(data.quickViewFullDetailMsg);
            $('#quickViewModal .full-pdp-link').attr('href', data.productUrl);
            $('#quickViewModal .size-chart').attr('href', data.productUrl);
            $('#quickViewModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#quickViewModal .enter-message').text(data.enterDialogMessage);
            $('#quickViewModal').modal('show');
            $('#quickViewModal').on('shown.bs.modal', function () {
                base.images();
                formLabels();
            });
            $('body').trigger('quickview:ready');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
            // console.error('Quickview Data Retrieval Error');
        }
    });
}

/**
 * replaces the content in the quick Add modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillQuickAddModalElement(selectedValueUrl) {
    var compareThere;
    if ($('.compare-btn')) {
        compareThere = true;
    } else {
        compareThere = false;
    }
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);
            var fullPdpLink = '<a class="full-pdp-link d-none" href=""></a>';
            var fullPdpBtn = '<a class="full-pdp-btn btn btn-outline-primary d-none" href="" ></a>';
            var $quickAddModal = $('#quickAddModal');
            //console.log(JSON.stringify(data));
            $quickAddModal.find('.modal-body').empty();
            $quickAddModal.find('.modal-body').html(parsedHtml.body);
            $quickAddModal.find('.modal-footer').html(parsedHtml.footer);
            $quickAddModal.find('.modal-footer .button-block').append(fullPdpBtn, fullPdpLink);
            if (compareThere === true) {
                var theURL = $quickAddModal.find('.selected').parent().attr('href');
                var urlParts = theURL.split('/');
                var lastPart = urlParts[urlParts.length - 1];
                $quickAddModal.find('.compare-btn input').attr('id', lastPart);
            }
            $quickAddModal.find('.full-pdp-link, .full-pdp-btn').text(data.quickViewFullDetailMsg);
            $quickAddModal.find('.full-pdp-link, .full-pdp-btn').attr('href', data.productUrl);
            $quickAddModal.find('.modal-header .close .sr-only').text(data.closeButtonText);
            $quickAddModal.find('.enter-message').text(data.enterDialogMessage);
            $quickAddModal.on('shown.bs.modal', function () {
                base.images();
                formLabels();
            });
            $('body').removeClass('modal-open').css('padding-right', '0');
            $('body').trigger('quickview:ready');
            $('.modal.show .product-quickview .prod-tile-promo').text('');
        },
        error: function () {
            // console.error('Quickadd Data Retrieval Error');
        }
    });
}
/**
 * replaces the content in the quick Add modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillQuickAddCompareModalElement(selectedValueUrl) {
    var compareThere;
    if ($('.compare-btn')) {
        compareThere = true;
    } else {
        compareThere = false;
    }
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);
            var fullPdpLink = '<a class="full-pdp-link d-none" href=""></a>';
            var fullPdpBtn = '<a class="full-pdp-btn btn btn-outline-primary d-none" href="" ></a>';
            var $quickAddModal = $('#quickAddModalCompare');

            // console.log(JSON.stringify(data));

            $quickAddModal.find('.modal-body').empty();
            $quickAddModal.find('.modal-body').html(parsedHtml.body);
            $quickAddModal.find('.modal-footer').html(parsedHtml.footer);
            $quickAddModal.find('.modal-footer .button-block').append(fullPdpBtn, fullPdpLink);
            // if (compareThere === true) {
            //     $quickAddModal.find('.compare-btn input').attr('id', data.productUrl);
            // }
            // if (compareThere === true) {
            //     var theURL = $quickAddModal.find('.selected').parent().attr('href');
            //     var urlParts = theURL.split('/');
            //     var lastPart = urlParts[urlParts.length - 1];
            //     $quickAddModal.find('.compare-btn input').attr('id', lastPart);
            // }
            $quickAddModal.find('.full-pdp-link, .full-pdp-btn').text(data.quickViewFullDetailMsg);
            $quickAddModal.find('.full-pdp-link, .full-pdp-btn').attr('href', data.productUrl);
            $quickAddModal.find('.modal-header .close .sr-only').text(data.closeButtonText);
            $quickAddModal.find('.enter-message').text(data.enterDialogMessage);
            $quickAddModal.on('shown.bs.modal', function () {
                base.images();
                formLabels();
            });
            $('body').removeClass('modal-open').css('padding-right', '0');
            $('body').trigger('quickview:ready');
            $('.modal.show .product-quickview .prod-tile-promo').text('');
        },
        error: function () {
            // console.error('Quickadd Data Retrieval Error');
        }
    });
}
module.exports = {
    showQuickview: function () {
        $('body').on('click', '.quickview', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var selectedValueUrl = $(this).closest('button.quickview').attr('data-href');
            $(e.target).trigger('quickview:show');
            getModalHtmlElement();
            fillModalElement(selectedValueUrl);
        });
    },
    showCompareQuickview: function () {
        $('body').on('click', '.quickview', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var selectedValueUrl = $(this).closest('button.quickaddcompare').attr('data-href');
            $(e.target).trigger('quickview:show');
            getModalCompareHtmlElement();
            fillModalElement(selectedValueUrl);
        });
    },
    showQuickAdd: function () {
        $('body').on('click', '.quickadd', function (e) {
            var selectedValueUrl = $(this).closest('.quickadd').attr('data-href');
            $(e.target).trigger('quickview:show');
            getQuickAddHtmlElement(e.target);
            // console.log(e.target);
            fillQuickAddModalElement(selectedValueUrl);
        });
    },
    showCompareQuickAdd: function () {
        $('body').on('click', '.quickaddcompare', function (e) {
            var selectedValueUrl = $(this).closest('.quickaddcompare').attr('data-href');
            $(e.target.parentNode).trigger('quickview:show');
            // console.log(e.target.parentNode);
            getQuickAddCompareHtmlElement(e.target.parentNode);
            fillQuickAddCompareModalElement(selectedValueUrl);
        });
    },
    focusQuickview: function () {
        $('body').on('shown.bs.modal', '#quickViewModal', function () {
            $('#quickViewModal .close').focus();
        });
    },
    focusCompareQuickview: function () {
        $('body').on('shown.bs.modal', '#quickViewModalCompare', function () {
            $('#quickViewModalCompare .close').focus();
        });
    },
    trapQuickviewFocus: function () {
        $('body').on('keydown', '#quickViewModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#quickViewModal',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.add-to-cart-global',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });

        $('body').on('keydown', '#quickAddModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#quickAddModal',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.add-to-cart-global',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    trapCompareQuickviewFocus: function () {
        $('body').on('keydown', '#quickViewModalCompare', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#quickViewModalCompare',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.add-to-cart-global',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });

        $('body').on('keydown', '#quickViewModalCompare', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#quickViewModalCompare',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.add-to-cart-global',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    availability: base.availability,
    addToCart: base.addToCart,
    addToCartAndBuyNow: base.addToCartAndBuyNow,
    showSpinner: function () {
        $('body').on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.modal-content').spinner().start();
        });
    },
    hideDialog: function () {
        $('body').on('product:afterAddToCart', function () {
            $('#quickAddModal').on('hidden.bs.modal', function (e) {
                if ($('.minicart-popover').is('.show')) {
                    $('body').addClass('modal-open');
                }
            });

            $('#quickViewModal').modal('hide');
            $('#quickAddModal').modal('hide');
        });
    },
    beforeUpdateAttribute: function () {
        $('body').on('product:beforeAttributeSelect', function () {
            $('.modal.show .modal-content').spinner().start();
        });
    },
    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            // console.log('running quickView updateAttribute');
            // console.log('response = ' + JSON.stringify(response, null, 2));
            if ($('.modal.show .product-quickview>.bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container)
                    .find('.product-id').text(response.data.product.id);
            } else if ($('.set-items').length) {
                response.container.find('.product-id').text(response.data.product.id);
            } else {
                $('.modal.show .product-quickview').data('pid', response.data.product.id).data('vid', response.data.product.id);
                if (response.data.product.tilePromoMessage) {
                    var seen = {};
                    $('.promo-col .prod-tile-promo').each(function () {
                        var txt = $(this).text();
                        if (seen[txt]) {
                            $(this).parent().remove();
                        } else {
                            seen[txt] = true;
                        }
                    });
                    var messages = '';
                    var tilePromoMessages = response.data.product.tilePromoMessage;

                    Object.keys(tilePromoMessages).forEach(function (key) {
                        messages += tilePromoMessages[key] + '<br>';
                    });

                    $('.modal.show .product-quickview .prod-tile-promo').html(messages);
                } else {
                    $('.modal.show .product-quickview .prod-tile-promo').text().empty();
                }

                $('.modal.show .full-pdp-link, .modal.show .full-pdp-btn')
                    .attr('href', response.data.product.selectedProductUrl);
            }
        });
    },
    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
        // update local add to cart (for sets)
            $('button.add-to-cart', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));
            if (response.product.productType === 'variant') {
                // it's a variant!
                
                // $('.compare-btn input', response.$productContainer).attr('id') = '';
                // $('.compare-btn input').attr('id').text(response.product.id);
                // $('.compare-btn input').attr('for')= '';
                // $('.compare-btn input').attr('for').empty().text(response.product.id);
            }
            // update global add to cart (single products, bundles)
            var dialog = $(response.$productContainer).closest('.quick-view-dialog');
                dialog.find('.compare-btn input').attr('id', response.product.id);
                dialog.find('.compare-btn label').attr('for', response.product.id);
            $('.add-to-cart-global', dialog).attr('disabled',
                !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available'));
            // $('.compare-btn', dialog).toggleClass('disabled',
            //     (!response.product.readyToOrder || !response.product.available));
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            var isQuickAdd = response.$productContainer.hasClass('product-quickadd');

            // bundle individual products
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);

            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            if ($('.product-availability', dialog).length) {
            // bundle all products
                var allAvailable = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('available'); });

                var allReady = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('ready-to-order'); });

                $('.global-availability', dialog)
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg', dialog).empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
                $('.add-to-cart-global').show();
                $('.full-pdp-btn').addClass('d-none');

                // single product
                $('.global-availability', dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .show()
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);

                if (isQuickAdd && response.product.productType !== 'master' && response.product.readyToOrder && !response.product.available) {
                // single product
                    $('.add-to-cart-global').hide();
                    $('.full-pdp-btn').removeClass('d-none');
                } else if (isQuickAdd) {
                    $('.global-availability', dialog).hide();
                }
            }
        });
    }
};
