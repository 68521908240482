'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var minChars = 3;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    // console.log('running getSuggestionsWrapper...');
    // console.log('scope:');
    // console.dir(scope);
    // return $(scope).siblings('.suggestions-wrapper');
    return $('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    // console.log('running clearModals...');
    $('body').removeClass('modal-open');
    $('header').siblings().attr('aria-hidden', 'false');
    $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    // console.log('running applyModals...');
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open');
        $('header').siblings().attr('aria-hidden', 'true');
        getSuggestionsWrapper(scope).find('.suggestions').addClass('modal');
    }
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    // console.log('running tearDownSuggestions...');
    $('input.search-field').val('');
    clearModals();
    $('.search-mobile .suggestions').unbind('scroll');
    $('.suggestions-wrapper').empty();
    $('.site-search').removeClass('active');
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    // console.log('running toggleSuggestionsIcon');
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    var container = $(scope).parents('.site-search').find('.suggestions-inner-wrapper');
    if (($(container).scrollTop() + $(container).innerHeight()) >= $(container)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    // console.log('running positionSuggestions...');
    // console.log('scope:');
    // console.dir(scope);
    var $header = $('.page > header');
    var $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
    var top;
    // console.log('suggestionsTop = ' + suggestionsTop);
    // console.log('suggestionsLeft = ' + suggestionsLeft);

    if (isMobileSearch(scope)) {
        top = parseInt($header.css('top'), 10);
        if ($header.hasClass('offset')) {
            $('.suggestions-inner-wrapper').height($(window).height() - $header.height()).parent().css('top', ($header.height() + top));
        } else {
            $('.suggestions-inner-wrapper').height($(window).height() - $header.height()).parent().css('top', $header.height());
        }

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    } else if ($('.suggestions-inner-wrapper').height() > $(window).height()) {
        $('.suggestions-inner-wrapper').height($(window).height() - $header.height());
        handleMoreContentBelowIcon(scope);
        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    $.spinner().stop();

    if (typeof (response) !== 'object') {
        $suggestionsWrapper.append(response).show();
        $(this).siblings('.reset-button').addClass('d-lg-block');
        positionSuggestions(this);

        if (isMobileSearch(this)) {
            toggleSuggestionsIcon('close');
            applyModals(this);
        }

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('input.search-field').attr('aria-describedby', 'search-result-count').parents('.site-search').addClass('active');
        } else {
            $('input.search-field').removeAttr('aria-describedby');
        }
    } else {
        $suggestionsWrapper.hide();
        $('.site-search').removeClass('active');
    }
}

/**
 * Toggle Search on Mobile
 */
function toggleMobileSearch() {
    $('.search-mobile-trigger').on('click', function () {
        $('.search-mobile').slideToggle();
    });
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    
    if ($(scope).val().length >= minChars) {
        $.spinner().start();
        
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        toggleSuggestionsIcon('search');
        $(scope).siblings('.reset-button').removeClass('d-lg-block');
        getSuggestionsWrapper(scope).empty();
        $('.site-search').removeClass('active');
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $('.suggestions .item');
    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        $('input.search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                $(this).removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $(this).removeProp('aria-selected');
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response, option) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

/**
 * Dynamically get the search format on each resize to deal with pivoting tablets.
 * @returns {string} the string 'desktop' or 'mobile'
 */
function getSearchFormat() {
    return window.matchMedia('(min-width: 992px').matches ? 'desktop' : 'mobile';
}
/**
 * Since our search box is not in a modal we need the suggestions to be rendered outside the
 * header element and positioned dynamically, rather than in relation to a passed-in DOM scope
 * based on the search form.
 */
function positionSearchSuggestions() {
    // console.log('running positionSearchSuggestions');
    var searchFormat = getSearchFormat();
    var $searchForm = searchFormat === 'desktop' ? $('.home-main-right .site-search__form') : $('.search-overlay .site-search__form');
    // console.log('@@@ in positionSearchSuggestions, $searchForm:');
    // console.dir($searchForm);
    var newTop = $searchForm.offset().top + $('.site-search__form').height() + 12 + 'px';
    var newLeft = $searchForm.offset().left + 'px';
    var newWidth = $searchForm.width() + 'px';
    // console.log('><><><><><>< newTop=' + newTop);
    // console.log('><><><><><>< newLeft=' + newLeft);
    // console.log('><><><><><>< newWidth=' + newWidth);
    $('.suggestions-wrapper').css({
        top: newTop,
        left: newLeft,
        width: newWidth
    });
}
window.positionSearchSuggestions = positionSearchSuggestions;

module.exports = function () {
    $(document).ready(function () {
        // console.log('$("#header-main").length=' + $("#header-main").length);
        if (window.matchMedia('(min-width: 992px').matches) {
            positionSearchSuggestions();
            $(window).smartresize(function () {
                positionSearchSuggestions();
            });
        }
        
        // this is to have data url for shoe previous
        $(document).on('click', '.category-base-url', function () {
            window.location.href = $(this).attr('data-url');
        });

        $('input[name="backUrl"]').val(window.location);

        $('.compare-products-form').on('submit', function () {
            var selectedProducts = $('.compare input:checked').map(function () { return this.id; }).get().join(',');
            window.history.pushState({}, document.title, window.location.href + '#' + selectedProducts);
            window.location.hash = selectedProducts;

            $(this).find('input[name="cgid"]').attr('value', $('input.category-id').val());
        });
    });
    $('#header-main').on('searchPanelOpened', function () {
        // console.log('@>@>@>@> search panel action');
        positionSearchSuggestions();
    });

    $('form[name="simpleSearch"]').submit(function (e) {
        if ($(this).find('.js-site-search-field').val() !== '') {
            var suggestionsList = $('.suggestions .item');
            if (suggestionsList.filter('.selected').length !== 0) {
                e.preventDefault();
                suggestionsList.filter('.selected').find('a')[0].click();
            }
        } else {
            e.preventDefault();
        }
    });

    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup focus', function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });

    toggleMobileSearch();

    $('body').on('click', function (e) {
        if (!$('.suggestions').has(e.target).length && !$(e.target).hasClass('search-field')) {
            $('.suggestions').hide();
            $('.site-search').removeClass('active');
        }
    });

    $('body').on('click touchend', '.search-mobile button.fa-close', function (e) {
        e.preventDefault();
        $('.suggestions').hide();
        toggleSuggestionsIcon('search');
        tearDownSuggestions();
    });

    $('.site-search .reset-button').on('click', function () {
        $(this).removeClass('d-lg-block');
    });

    $(window).smartresize(function () {
        if ($('.suggestions').length && window.matchMedia('(max-width: 48em)').matches) {
            positionSuggestions('input.search-field');
        }
    });

    $('body').on('click touchend', '.in-stock-filter', function (e) {
        e.preventDefault();

        var url = $(this).data('url');
        $.spinner().start();

        $.ajax({
            url: url,
            data: {
                selectedUrl: $(this).data('href')
            },
            method: 'GET',
            success: function (response) {
                parseResults(response);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};
